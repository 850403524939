import React from "react";
import "./footer.css";

import logo from "../../Images/logo_1.png";
import twitter from "../../Images/twitter.svg";
import discord from "../../Images/discord.svg";
import gitbook from "../../Images/gitbook.png";

const Footer = () => {
  return (
    <div className="FSuper">
      <div className="Fcontainer">
        <div className="FL">
          <img src={logo} alt="" />
        </div>
        <div className="FT">
          <a href="https://twitter.com/SolDuki" target="_blank">
            <img src={twitter} alt="" />
          </a>
          <a href="https://t.me/DukiSol" target="_blank">
            <img src={discord} alt="" />
          </a>
        </div>
        {/* <p>@DUDU</p> */}
      </div>
    </div>
  );
};

export default Footer;