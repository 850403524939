import React from "react";
import "./navbar.css";

import logo from "../../Images/logo_1.png";
import twitter from "../../Images/twitter.svg";
import discord from "../../Images/discord.svg";
import gitbook from "../../Images/gitbook.png";

const Navbar = () => {
  return (
    <div className="NBSuper">
      <div className="NBContainer">
        <img src={logo} alt="" />
        <div className="FT">
          <a href="https://twitter.com/SolDuki" target="_blank">
            <img src={twitter} alt="" />
          </a>
          <a href="https://t.me/DukiSol" target="_blank">
            <img src={discord} alt="" />
          </a>
          {/* <a
            href="https://degenxmas.gitbook.io/degenxmas-on-solana/"
            target="_blank"
          >
            <img src={gitbook} alt="gitbook" />
          </a> */}
          {/* <a
            className="MintBtn"
            href="https://launchmynft.io/collections/Bx7FAGCzLqC5zcYRq8FDzPMnMiFfpAjMFjYjrhNXWzcX/7WuxplPR139F6D9brsty"
            target="_blank"
            alt="mint button"
          >
            <p>MINT</p>
          </a> */}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
